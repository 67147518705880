<template>
  <div v-if="word" class="about">
    <div class="stk animate__animated animate__rotateInDownLeft">
      <!-- <div class="stkBg"></div> -->
      <img src="./../assets/banner-1.png" alt="" />
      <img class="img2" src="./../assets/slide-inner-1.png" alt="" />
      <div class="font_detail">
        <h2>{{ word.menu.titleTop7[language] }}</h2>
        <p class="jianjie">
          {{ word.menu.jieshao[language] }}
        </p>

        <div class="jiage" @click="goToPrice">
          {{ word.menu.jiage[language] }}
        </div>
      </div>
      <div class="container">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </div>
    <div id="product-title" class="animate__animated animate__fadeInUp">
      <div class="about-title">{{ word.about.title[language] }}</div>
      <div class="about-text">
        <div class="about-swiper">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div><img src="./../assets/about-1.png" alt="" /></div>
              </div>
              <div class="swiper-slide">
                <div><img src="./../assets/about-2.png" alt="" /></div>
              </div>
              <div class="swiper-slide">
                <div><img src="./../assets/about-3.png" alt="" /></div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>

        <div class="text" v-if="language == 'CN'">
          嘉兴优尼信息科技有限公司是一家专业提供
          CPaaS接入方式的云通信服务的互联网企业。目前，经过5年的努力在中国香港、新加坡、印尼、越南、菲律宾、印度设有分公司，为当地企业客户提供融合通信云服务；在中国，我们专注中国出海企业的云通信服务。嘉兴优尼信息科技有限公司成立的五年内，我们陆续推出了全球短信服务、VoIP
          语音服务、WhatsApp、号码检测等服务，旨在为中国出海企业解决通信需求问题，帮助中国出海企业走向世界。我们在未来将会继续努力，推出各种优秀的产品，成为中国出海企业云通信服务的排头兵。
        </div>
        <div class="text1Box">
          <div class="text1" v-if="language == 'EN'">
            Jiaxing Optimal IT Co., Ltd is an Internet company specializing in
            providing cloud communication services with CPaaS access method.
            Currently, after five years of hard work, we have branches in Hong
            Kong, Singapore, Indonesia, Vietnam, Philippines, and India to
            provide integrated communication cloud services for local enterprise
            customers; in China, we focus on cloud communication services for
            Chinese enterprises going overseas.
          </div>
          <div class="text1" v-if="language == 'EN'">
            Within five years since the establishment of Jiaxing Optimal IT Co.,
            Ltd, we have successively launched global SMS services, VoIP voice
            services, WhatsApp, number check and other services, aiming to solve
            the communication needs of Chinese overseas companies and help
            Chinese overseas companies go global.
          </div>
          <div class="text1" v-if="language == 'EN'">
            We will continue to work hard in the future to launch a variety of
            excellent products and become the leader in cloud communication
            services for Chinese overseas enterprises.
          </div>
        </div>
      </div>
    </div>
    <div class="animate__animated animate__fadeInUp">
      <div class="about-title">{{ word.about.cultureTitle[language] }}</div>
      <div class="culture">
        <div class="about-img">
          <img src="./../assets/culture.jpg" alt="" />
        </div>
        <div class="culture-list">
          <div>
            <div>
              <i class="iconfont">&#xe606;</i><span>{{ word.about.passion[language] }}</span>，{{ word.about.passionSubtitle[language] }}
            </div>
            <div class="subtitle">
              <div>{{ word.about.passion1[language] }}</div>
              <div>{{ word.about.passion2[language] }}</div>
              <div>{{ word.about.passion3[language] }}</div>
            </div>
          </div>
          <div>
            <div>
              <i class="iconfont">&#xf898;</i><span>{{ word.about.efficient[language] }}</span>，{{ word.about.efficientSubtitle[language] }}
            </div>
            <div class="subtitle">
              <div>{{ word.about.efficient1[language] }}</div>
              <div>{{ word.about.efficient2[language] }}</div>
              <div>{{ word.about.efficient3[language] }}</div>
            </div>
          </div>
          <div>
            <div>
              <i class="iconfont">&#xe7f1;</i><span>{{ word.about.study[language] }}</span>，{{ word.about.studySubtitle[language] }}
            </div>
            <div class="subtitle">
              <div>{{ word.about.study1[language] }}</div>
              <div>{{ word.about.study2[language] }}</div>
              <div>{{ word.about.study3[language] }}</div>
            </div>
          </div>
          <div>
            <div>
              <i class="iconfont">&#xe602;</i><span>{{ word.about.innovate[language] }}</span>，{{ word.about.innovateSubtitle[language] }}
            </div>
            <div class="subtitle">
              <div>{{ word.about.innovate1[language] }}</div>
              <div>{{ word.about.innovate2[language] }}</div>
              <div>{{ word.about.innovate3[language] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="about-title">{{ word.about.branch[language] }}</div>
      <div style="width: 80%; margin: 0 auto">
        <img style="width: 100%" src="./../assets/map.png" alt="" />
      </div>
    </div>
    <div class="map-tips">
      <img src="./../assets/etc.png" alt="" />
      {{ language == "CN" ? "筹备中" : "In Preparation" }}
    </div>
    <div class="about-address">
      <div class="about-add"></div>
      <div class="about-add-text">
        <div class="address">
          <div class="add-info">
            <div class="add-item">
              <img src="./../assets/1.png" alt="" />
              <div>{{ word.about.company[language] }}</div>
            </div>
            <div v-if="language == 'CN'">
              <i class="iconfont">&#xe88b;</i>
              <div>0573-82828875</div>
            </div>

            <div v-else>
              <i class="iconfont">&#xe88b;</i>
              <div>0086-573-82828875</div>
            </div>
            <div>
              <i class="iconfont">&#xe64e;</i>
              <div>{{ word.foot.company.specific[language] }}</div>
            </div>
          </div>
        </div>
        <div class="address">
          <div class="add-info">
            <div class="add-item">
              <img src="./../assets/2.png" alt="" />
              <div>YONI INTERNATIONAL PTE.LTD.</div>
            </div>
            <div>
              <i class="iconfont">&#xe64e;</i>
              <div>Singapore</div>
            </div>
          </div>
        </div>
        <div class="address">
          <div class="add-info">
            <div class="add-item">
              <img src="./../assets/3.png" alt="" />
              <div>PT YONI INFORMATION TECHNOLOGY</div>
            </div>
            <div>
              <i class="iconfont">&#xe64e;</i>
              <div>Indonesia</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable -->
<script>
import "animate.css";
import word from "./../assets/json/language.json";
export default {
  name: "About",
  components: {},
  data() {
    return {
      language: "",
      word: word,
    };
  },
  created() {
    this.language = localStorage.getItem("language");
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 可选的平滑滚动效果
    });
  },
  methods: {
    goToPrice() {
      this.$router.push("/price");
    },
  },
};
</script>

<style lang="scss" scoped>
.img2 {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 400px !important;
  height: 400px !important;
}
.stk {
  width: 100%;
  height: 100vh;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }

  .stkBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #424267 !important;
    opacity: 0.5 !important;
    z-index: 2;
  }

  .font_detail {
    width: 65%;
    font-size: 30px !important;
    color: #fff !important;
    line-height: 1.5em !important;
    font-weight: 800 !important;
    position: absolute;
    bottom: 130px;
    z-index: 33;
    left: 40px;
  }

  .jianjie {
    line-height: 1.5em !important;
    font-size: 18px;
    width: 60%;
    color: #fff !important;
  }

  .jiage {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    width: 150px;
    border: 2px solid #fff;
    cursor: pointer;
    z-index: 999;

    &:hover {
      background: #108ccf;
    }
  }
}

.about {
  width: 100%;
  overflow: hidden;
}
.about-title {
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 36px;
  text-align: center;
}
.about-text {
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    max-width: 550px;
    line-height: 2;
    text-indent: 2em;
    margin-left: 50px;
  }

  .text1Box {
    .text1 {
      max-width: 550px;
      line-height: 1.5;
      text-indent: 2rem;
      margin-left: 50px;
    }
  }
}
.about-img {
  width: 630px;
  max-width: 50%;
  padding: 20px 0;
  img {
    width: 100%;
  }
}
.culture {
  display: flex;
  justify-content: center;
  .culture-list {
    margin-left: 50px;
    margin-top: -10px;
    width: 500px;
    > div {
      height: 130px;
      font-size: 18px;
      i {
        font-size: 22px;
        margin-right: 10px;
        color: #108ccf;
        & + span {
          color: #108ccf;
          font-size: 24px;
        }
      }
      .subtitle {
        padding-left: 32px;
        margin-top: 6px;
        font-size: 14px;
        color: #999;
      }
    }
  }
}
.about-add-text {
  display: flex;
  .address {
    padding: 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grow: 1;
    .add-info {
      > div {
        display: flex;
        align-items: top;
        margin: 20px 0;
        padding-left: 50px;
        max-width: 350px;
        &:first-of-type {
          align-items: center;
        }
        .add-title {
          margin-right: 10px;
        }
        > img {
          margin-left: -10px;
          margin-right: 10px;
          width: 40px;
        }
        i {
          font-size: 24px;
          margin-right: 15px;
        }
      }
    }
  }
}
.map-tips {
  margin-top: -60px;
  font-size: 12px;
  text-align: right;
  color: #999999;
  margin-right: 40px;
  display: flex;
  align-items: center;
  float: right;
  img {
    width: 30px;
    margin-right: 10px;
  }
}
.about-address {
  position: relative;
}
.about-add {
  background-image: url("./../assets/bg.jpg");
  opacity: 0.3;
  background-size: 100%;
  background-color: transparent;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.about-swiper {
  width: 500px;
  position: relative;
  .swiper-container {
    width: 500px;
    height: 300px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .swiper-pagination {
    bottom: -30px;
    left: 0;
  }
}
</style>
